

<template>
    <v-container>
        <v-row>
            <v-col
            class="mx-auto"
            cols="12"
            sm="10">
                <div class="d-flex justify-space-between">
                    <v-btn
                    class="mb-3"
                    color="primary"
                    @click="closeDetail">
                        <v-icon>
                            mdi-arrow-left-bold
                        </v-icon>
                    </v-btn>
                </div>
                <v-skeleton-loader ref="skeleton" type="table" v-if="api.isLoading">

                </v-skeleton-loader>
                <v-card class="pa-3" v-if="manual!=null" elevation="0">
                    <v-toolbar color="secondary white--text" flat class="">
                        <v-toolbar-title class="text-h4">
                            How to {{ manual.name }}

                        </v-toolbar-title>
                        <v-spacer>
                        </v-spacer>
                        <v-btn @click="redirectUpdateSteps" 
                        v-if="this.$store.getters.getRole=='operation'" color="primary">
                            Update steps
                        </v-btn>
                    </v-toolbar>
                    <v-card class=" mt-3 mb-6" v-for="(item,index) in manualStep"
                        :key="index">
                        <v-sheet>
                            <v-toolbar class="text-h6" flat v-if="item.title!='null'">
                               {{ (index+1)+" ) " }} {{ item.title }}
                            </v-toolbar>
                            <hr class="ma-4"/>
                            <!-- <div class="d-flex justify-center"> -->
                                <v-img 
                                @click="redirectReadImage(item.attachment_url)" v-if="item.attachment_url!=null" class="mx-auto" :src=item.attachment_url 
                                :width="imageSize"
                                :style="{cursor:cursorStyle}" >
                                </v-img>
                                <!-- <v-container>

                                    <a class="d-flex justify-start" target="_blank" v-if="item.attachment_url!=null" :href="item.attachment_url">
                                        Download attachment
                                    </a>
                                </v-container> -->
                            <!-- </div> -->
                        </v-sheet>
                        <v-container v-if="item.step!='null' || item.step!=' '">
                            <div class="text-h5 ma-3 font-weight-bold">
                                Description
                            </div>
                            <mavon-editor
                            elevation-1
                            
                            style="z-index:0;min-height:100px;height:auto;width:100%"
                            height="auto"
                            width="auto"
                            defaultOpen="preview" 
                            :toolbarsFlag="false" 
                            :subfield="false"
                            language="en"
                            v-model="item.step"
                            :editable="false">
                            </mavon-editor>
                        </v-container>
                    </v-card>
                    <v-card v-if="manualStep.length==0">
                        <div class="text-h5 pa-3">
                            No manual found
                        </div>
                    </v-card>
                    <!-- <v-carousel class="pt-4" width="auto" height="auto"  hide-delimiters
                    :continuous="false" v-if="manualStep!=null">
                        <v-carousel-item >
                           
                        </v-carousel-item>
                    </v-carousel> -->
                </v-card>
                
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import mavonEditor from 'mavon-editor';
export default {
    components:{
        'mavon-editor':mavonEditor.mavonEditor,
    //
    },
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
    cursorStyle:'pointer',
    img:'http://127.0.0.1:8000/storage/vision/form/receipt/MqFj2ZfdupIdyrPBCTn47FyAiNB6ix9nP0SlLGia.png',
    manual:null,
    manualStep:null,
    steps:"Lorenum alppoisse lopopoossa sliif slowma;sss",
    imageSize:'400px',
    headersTeam:[
        {
            text:'Name',
            value:'memberName',
            align:'start',
        },{
            text:'Email',
            value:'memail',
        }
    ],
    api:{
        isSuccesful:false,
        isLoading:false,
        isError:false,
        error:null,
        sucess:null,
        url:null,
    }
    }),
    created() {
        this.api.callbackReset = () => 
        {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) =>
        {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) =>
        {
            if(resp.class=="getManualDetail"){
                this.manual = resp.data.manual;
                this.manualStep = resp.data.manual_step;
            }
            this.api.isSuccesful = true;
            this.api.isError = false;
            this.api.isLoading = false;

        }
    },
    mounted() {
        this.fetch();
    },
    methods: {
       fetch(){
        let fetchManualDetailApi = this.fetchManualDetail();
        this.$axios.all([
            this.$api.fetch(fetchManualDetailApi),
        ]);
       },
       fetchManualDetail(){
        let tempApi =this.$_.clone(this.api);

        tempApi.method = "GET";
        tempApi.url = process.env.VUE_APP_SERVER_API+"/manual/detail/"+this.$router.history.current.params.id;
        return tempApi;
       },
        closeDetail(){
            this.$router.go(-1);
        },
        redirectUpdateSteps(){
            this.$router.push({name:'PageManualStepList',params:{'id':this.$router.history.current.params.id}});
        },
        redirectReadImage(url){
            window.open(url, '_blank');
        },
        expandImageSize(){
            this.imageSize="600px";
        },
        shrinkImageSize(){
            this.imageSize="400px";
        }
    }
}
</script>